var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "container mt-4 mb-4" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12 mx-auto" }, [
          _c("h3", [
            _vm._v("Thank you for visiting the Desert Nomads Golf Club"),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "row mt-4" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("p", [
            _vm._v(
              " Welcome to the Desert Nomads Golf Club, formed to provide golfers a fair, fun and easy way to keep a lifetime scoring record/handicap and enjoy the game of golf. Its members are golfers who prefer to enjoy all courses the Southwest has to offer versus joining a specific course-based club. We put together regular scheduled outings, last minute outings and tournaments throughout the year. Our signature event is a four day Ryder Cup competition in late Summer known as the Slyder Cup. "
            ),
          ]),
          _c("p", [
            _vm._v(
              " With the help of Internet technology and a strong tournament committee, the founders designed Desert Nomads to be the most 'sandbagger' proof golf club in the world. The ease of Internet posting of scores along with an extremely dynamic peer review system provide the competition committee statistical information to make informed decisions on accepting or adjusting member handicaps for play. This exclusive handicap system adopts even more restrictive demands than the USGA that compel members to actively participate together to provide accurate and verified scoring. This ensures that all our events are setup for fair competition with a higher likelihood of dramatic finishes and a lower likelihood sandbagger problems. "
            ),
          ]),
          _c("p", [
            _vm._v(
              "Features found within the Desert Nomads Golf Club App, many of which are exclusive:"
            ),
          ]),
          _c("ul", [
            _c("li", [
              _vm._v(
                " easy online outing and tournament signup with simple payment of entry fees; "
              ),
            ]),
            _c("li", [
              _vm._v(" real time tournament scoring w/ leaderboard; "),
            ]),
            _c("li", [
              _vm._v(
                " real time revision schedule, post a score, find out your new handicap; "
              ),
            ]),
            _c("li", [
              _vm._v(
                " instant production of your real time USGA handicap card at any time; "
              ),
            ]),
            _c("li", [
              _vm._v(
                " course database with slope and rating figures with members able to update course conditions and update information; "
              ),
            ]),
            _c("li", [
              _vm._v(
                " the ability to post and broadcast unfilled reserved tee times to encourage members to participate together; "
              ),
            ]),
            _c("li", [
              _vm._v(
                " extremely dynamic peer review including handicap graphs and additional ability metrics; "
              ),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }